<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Titel</th>
          <th class="text-left">Bereich</th>
          <th class="text-left">Autor</th>
          <th class="text-left">Datum</th>
          <th class="text-left">Markiert von</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in posts"
          :key="item.id"
          @click="post = item"
        >
          <td>{{ item.title }}</td>
          <td>{{ getViewName(item.viewId) }}</td>
          <td>{{ $root.getUserName(item.userId) }}</td>
          <td>{{ item.ts | date }}</td>
          <td>{{ getUsersNames(item.flaggedBy) }}</td>
          <td width="120" class="text-right">
            <v-btn icon :loading="item._loading" @click.stop="unflagPost(item)">
              <v-icon title="Zulassen">mdi-check</v-icon>
            </v-btn>
            <v-btn icon :loading="item._loading" @click.stop="deletePost(item)">
              <v-icon title="Löschen">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog
      :value="post !== null"
      max-width="500"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="post = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-toolbar>
        <post-detail
          v-if="post"
          :post="post"
          hide-comments
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PostDetail from '@/components/posts/PostDetail'

export default {
  name: 'ObjectedPosts',
  components: { PostDetail },
  data () {
    return {
      posts: null,
      post: null
    }
  },
  methods: {
    getViewName (viewId) {
      return this.$cfg.views.find(v => v.id = viewId)?.name || ''
    },
    getUsersNames (userIds) {
      return userIds.map(userId => this.$root.getUserName(userId)).join(', ')
    },
    async unflagPost (post) {
      post._loading = true
      const unflagPostFunc = this.$fb.fn.httpsCallable('unflagPost')
      const response = await unflagPostFunc({
        id: post.id
      })
      if (response.data.ok) {
        this.posts = this.posts.filter(p => p.id !== post.id)
      }
      post._loading = false
    },
    async deletePost (post) {
      post._loading = true
      const deletePostFunc = this.$fb.fn.httpsCallable('deletePost')
      const response = await deletePostFunc({
        id: post.id
      })
      if (response.data.ok) {
        this.posts = this.posts.filter(p => p.id !== post.id)
      }
      post._loading = false
    }
  },
  created () {
    const moderatorOfTags = []
    const moderatorOfViews = []
    if (this.$root.isModerator) {
      Object.entries(this.$root.tags).forEach(([id, tag]) => {
        if (tag.moderators?.includes(this.$root.userId)) {
          moderatorOfTags.push(id)
        }
      })
      Object.entries(this.$root.views).forEach(([id, view]) => {
        if (view.moderators?.includes(this.$root.userId)) {
          moderatorOfViews.push(id)
        }
      })
    }

    this.$fb.db.collection('posts')
      .where('flagged', '==', true)
      .get()
      .then(snap => {
        const posts = []
        snap.forEach(doc => {
          const post = { id: doc.id, ...doc.data(), _loading: false }
          if (this.$root.isAdmin ||
            (!post.collection && post.tagIds && !post.tagIds.find(tagId => !moderatorOfTags.includes(tagId))) ||
            (post.collection && moderatorOfViews.includes(post.collection))
          ) {
            posts.push(post)
          }
        })
        posts.sort((a, b) => b.ts.seconds < a.ts.seconds ? -1 : 1)
        this.posts = posts
      })
  }
}
</script>
