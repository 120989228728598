<template>
  <div class="d-flex flex-column" style="height: 100%">
    <div class="scrollable" style="flex: 1">
      <v-container>
        <post-card :post="post" detail class="mb-5" @load="$emit('load', $event)" @update="$emit('update')"/>

        <div v-if="!hideComments">
          <v-sheet
            v-for="(comment, i) in comments"
            :key="i"
            color="grey lighten-4"
            rounded
            class="pa-2 mb-2"
          >
            <div class="d-flex align-center">
              <span class="text-subtitle-2">{{ $root.getUserName(comment.userId) }}</span>
              <v-spacer></v-spacer>
              <span class="pl-3 caption">{{ comment.ts | datetimeago }}</span>
              <v-icon v-if="isCommentEditable(comment)" small class="pl-3" @click="deleteCommentId = comment.id">mdi-delete</v-icon>
            </div>
            <div class="text-body-2">{{ comment.msg }}</div>
          </v-sheet>
        </div>
      </v-container>
    </div>

    <message-editor v-if="!hideComments" :submit="submitComment"/>

    <confirm-dlg
      :value="!!deleteCommentId"
      text="Kommentar löschen?"
      @ok="deleteComment"
      @input="deleteCommentId = null"
    />
  </div>
</template>

<script>
import PostCard from '@/components/posts/PostCard'
import MessageEditor from '@/components/base/MessageEditor'
import ConfirmDlg from '@/components/base/ConfirmDlg'

export default {
  name: 'PostDetail',
  components: { PostCard, MessageEditor, ConfirmDlg },
  props: {
    post: Object,
    hideComments: Boolean
  },
  data () {
    return {
      comments: null,
      deleteCommentId: null
    }
  },
  methods: {
    isCommentEditable (comment) {
      return comment && (comment.userId === this.$root.userId || this.$root.isAdmin)
    },
    async submitComment ({ text }) {
      const commentPostFunc = this.$fb.fn.httpsCallable('commentPost')
      const response = await commentPostFunc({
        postId: this.post.id,
        msg: text
      })
      if (response.data.ok) {
        this.$emit('update')
      }
    },
    async deleteComment () {
      const deleteCommentFunc = this.$fb.fn.httpsCallable('deleteComment')
      const response = await deleteCommentFunc({ id: this.deleteCommentId, postId: this.post.id })
      if (response.data.ok) {
        this.$emit('update')
      }
      this.deleteCommentId = null
    }
  },
  created () {
    this.unsubscribe = this.$fb.db.collection('posts/' + this.post.id + '/comments')
      .orderBy('ts')
      .onSnapshot(snap => {
        const comments = []
        snap.forEach(doc => {
          const comment = {
            id: doc.id,
            ...doc.data()
          }
          if (!comment._deleted) {
            comments.push(comment)
          }
        })
        this.comments = comments
      })
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>
