<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>Posts</v-tab>
      <v-tab>Events</v-tab>
      <v-tab v-if="$cfg.objections">Markiert</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <moderated-posts/>
      </v-tab-item>
      <v-tab-item>
        <moderated-events/>
      </v-tab-item>
      <v-tab-item v-if="$cfg.objections">
        <objected-posts/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ModeratedEvents from '@/components/admin/ModeratedEvents'
import ModeratedPosts from '@/components/admin/ModeratedPosts'
import ObjectedPosts from '@/components/admin/ObjectedPosts'

export default {
  name: 'ModeratedAdmin',
  components: { ModeratedEvents, ModeratedPosts, ObjectedPosts },
  data () {
    return {
      tab: null
    }
  }
}
</script>
