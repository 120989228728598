<template>
  <v-card :outlined="!$listeners.click" v-on="$listeners.click ? { click: () => $emit('click', postData) } : {}">
    <v-sheet v-if="postData && postData.schedule" color="grey" dark class="pa-2">{{ $t('scheduled_post') }}</v-sheet>
    <v-sheet v-if="postData && postData.reviewed === false" color="grey" dark class="pa-2">{{ $t('moderated_post') }}</v-sheet>

    <v-card-title class="d-flex align-start justify-space-between flex-nowrap">
      <div>
        <div>{{ postData ? postData.title : '' }}</div>
        <span class="caption caption-label">{{ postData ? (postData.schedule || postData.ts) : '' | datetimeago }}</span>
      </div>
      <user-avatar v-if="user" :user="user"/>
    </v-card-title>

    <v-card-text v-if="postData">
      <v-row>
        <v-col v-if="postData.img" cols="12" sm="4">
          <img-cmp :img="postData.img" />
        </v-col>
        <v-col cols="12" :sm="postData.img ? 8 : 12">
          <div v-if="postData.text" class="mb-3">
            <div v-if="!detail" v-text="html2text(postData.text)" class="text-short"/>
            <div v-else v-html="postData.text" class="html-text"/>
          </div>
          <files-list v-if="postData.files && postData.files.length > 0" :files="postData.files" class="mb-3"/>
          <plug-price-view v-if="plugins.includes('price')" :item="postData" class="my-3"/>
          <plug-address-view v-if="plugins.includes('address')" :item="postData" short class="my-3"/>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="align-end">
      <div>
        <v-btn
          v-for="(reaction) in reactions" :key="reaction.emoji"
          rounded
          depressed
          :class="{ 'reaction-btn-reacted': reaction.reacted }"
          class="reaction-btn mr-1 mb-1"
          @click.stop="react(reaction.emoji)"
        >
          {{ reaction.emoji }}
          {{ reaction.count }}
        </v-btn>
        <v-btn icon class="mb-1" @click.stop="showEmojiPicker = !showEmojiPicker">
          <v-icon small>mdi-emoticon-outline</v-icon>
        </v-btn>
      </div>
      <v-spacer style="min-width: 1rem"/>
      <v-btn text :color="commented ? 'blue' : 'grey'" style="min-width: unset; padding: 0;">
        <v-icon left>mdi-comment-outline</v-icon>
        {{ postData && postData.commentsCount ? postData.commentsCount : 0 }}
      </v-btn>
    </v-card-actions>

    <v-dialog
      v-model="showEmojiPicker"
      transition="dialog-bottom-transition"
    >
      <v-emoji-picker v-if="showEmojiPicker" @select="selectEmoji"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import FilesList from '@/components/base/FilesList'
import ImgCmp from '@/components/base/ImgCmp'
import UserAvatar from '@/components/base/UserAvatar'
import HelpersMixin from '@/mixins/HelpersMixin'
import PlugsMixin from '@/mixins/PlugsMixin'
import PlugAddressView from '@/components/plugs/PlugAddressView'
import PlugPriceView from '@/components/plugs/PlugPriceView'

export default {
  name: 'PostCard',
  components: { VEmojiPicker, FilesList, ImgCmp, UserAvatar, PlugAddressView, PlugPriceView },
  mixins: [HelpersMixin, PlugsMixin],
  inject: ['config', 'posts'],
  props: {
    postId: String,
    post: Object,
    detail: Boolean
  },
  data () {
    return {
      postData: null,
      reacting: false,
      showEmojiPicker: false
    }
  },
  computed: {
    user () {
      return this.postData?.userId ? {
        id: this.postData.userId,
        ...this.$root.users[this.postData.userId]
      } : null
    },
    reactions () {
      const reactions = []
      if (this.postData?.reactions) {
        Object.keys(this.postData.reactions).forEach(r => {
          reactions.push({
            emoji: r,
            count: this.postData.reactions[r].length,
            reacted: this.postData.reactions[r].includes(this.$root.userId)
          })
        })
        reactions.sort((a, b) => b.count - a.count)
      }
      return reactions
    },
    commented () {
      return !!this.postData?.commentsCount
    }
  },
  methods: {
    async load () {
      this.$fb.db.doc('posts/' + this.postId).get()
        .then(doc => {
          this.postData = {
            id: this.postId,
            ...doc.data()
          }
          this.$emit('load', this.postData)
        })
    },
    async selectEmoji (emoji) {
      this.react(emoji.data)
      this.showEmojiPicker = false
    },
    async react (emoji) {
      // optimistic local immediate execution
      if (this.postData.reactions === undefined) {
        this.postData.reactions = {}
      }
      if (this.postData.reactions[emoji] === undefined) {
        this.postData.reactions[emoji] = []
      }
      if (!this.postData.reactions[emoji].includes(this.$root.userId)) {
        // react (add)
        if (this.postData.reactions === undefined) {
          this.postData.reactions = {}
        }
        if (this.postData.reactions[emoji] === undefined) {
          this.postData.reactions[emoji] = []
        }
        this.postData.reactions[emoji].push(this.$root.userId)
      } else {
        // unreact (remove)
        const reactIdx = this.postData.reactions[emoji].indexOf(this.$root.userId)
        this.postData.reactions[emoji].splice(reactIdx, 1)
        // if we're last, remove this one completly (misspressed)
        if (this.postData.reactions[emoji].length === 0) {
          delete this.postData.reactions[emoji]
        }
      }
      this.postData.reactions = { ...this.postData.reactions }

      // submit
      const reactPostFunc = this.$fb.fn.httpsCallable('reactPost')
      const response = await reactPostFunc({
        postId: this.postId || this.post.id,
        emoji
      })
      if (response.data.ok) {
        this.postData.reactions = response.data.reactions
        this.postData.commentsCount = response.data.commentsCount
      }
      this.$emit('update')
    },
    async openFile (file) {
      const fileUrl = await this.getFileURL(file.src)
      window.open(fileUrl, '_blank')
    }
  },
  watch: {
    post (post) {
      if (post) {
        this.postData = { ...this.post }
      }
    },
    'posts.updatedPostId' (updatedPostId) {
      if (this.postId && this.postId === updatedPostId) {
        this.load()
      }
    }
  },
  created () {
    if (this.post) {
      this.postData = { ...this.post }
    } else {
      this.load()
    }
  }
}
</script>

<style scoped>
.reaction-btn {
  height: 30px !important;
  min-width: 50px !important;
  padding: 0 8px !important;
  border: thin solid lightgrey;
}
.reaction-btn-reacted {
  border: thin solid grey;
}
</style>
