<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Titel</th>
          <th class="text-left">Autor</th>
          <th class="text-left">Erstelldatum</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in events"
          :key="item.id"
          @click="event = item"
        >
          <td>{{ item.title }}</td>
          <td>{{ $root.getUserName(item.userId) }}</td>
          <td>{{ item.ts | date }}</td>
          <td width="120" class="text-right">
            <v-btn icon :loading="item._loading" @click="reviewEvent(item)">
              <v-icon title="Freischalten">mdi-check</v-icon>
            </v-btn>
            <v-btn icon :loading="item._loading" @click="deleteEvent(item)">
              <v-icon title="Löschen">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog
      :value="event !== null"
      max-width="500"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="event = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-toolbar>
        <event-detail
          v-if="event"
          :event="event"
          hide-comments
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventDetail from '@/components/events/EventDetail'

export default {
  name: 'ModeratedEvents',
  components: { EventDetail },
  data () {
    return {
      events: null,
      event: null
    }
  },
  methods: {
    async reviewEvent (event) {
      event._loading = true
      const reviewEventFunc = this.$fb.fn.httpsCallable('reviewEvent')
      const response = await reviewEventFunc({
        id: event.id
      })
      if (response.data.ok) {
        this.events = this.events.filter(p => p.id !== event.id)
      }
      event._loading = false
    },
    async deleteEvent (event) {
      event._loading = true
      const deleteEventFunc = this.$fb.fn.httpsCallable('deleteEvent')
      const response = await deleteEventFunc({
        id: event.id
      })
      if (response.data.ok) {
        this.events = this.events.filter(p => p.id !== event.id)
      }
      event._loading = false
    }
  },
  created () {
    this.$fb.db.collection('events')
      .where('reviewed', '==', false)
      .get()
      .then(snap => {
        const events = []
        snap.forEach(doc => {
          events.push({
            id: doc.id,
            ...doc.data(),
            _loading: false
          })
        })
        events.sort((a, b) => b.ts.seconds < a.ts.seconds ? -1 : 1)
        this.events = events
      })
  }
}
</script>
